body {
  font-family: "Open Sans";
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
